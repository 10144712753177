const actions = {
  SET_STATE: 'rptreport/SET_STATE',

  GET_TRANSACTIONS_REPORT: 'rptreport/GET_TRANSACTIONS_REPORT',
  RESET_TRANSACTIONS_REPORT: 'rptreport/RESET_TRANSACTIONS_REPORT',

  GET_DAILY_VOUCHER_REPORT: 'rptreport/GET_DAILY_VOUCHER_REPORT',
  RESET_DAILY_VOUCHER_REPORT: 'rptreport/RESET_DAILY_VOUCHER_REPORT',

  GET_PRODUCT_WISE_GROSS_PROFIT_REPORT: 'rptreport/GET_PRODUCT_WISE_GROSS_PROFIT_REPORT',
  RESET_PRODUCT_WISE_GROSS_PROFIT_REPORT: 'rptreport/RESET_PRODUCT_WISE_GROSS_PROFIT_REPORT',

  GET_GROUP_WISE_SALE_REPORT: 'rptreport/GET_GROUP_WISE_SALE_REPORT',
  RESET_GROUP_WISE_SALE_REPORT: 'rptreport/RESET_GROUP_WISE_SALE_REPORT',

  GET_AUDIT_REPORT: 'rptreport/GET_AUDIT_REPORT',
  RESET_AUDIT_REPORT: 'rptreport/RESET_AUDIT_REPORT',

  GET_STOCK_REPORT: 'rptreport/GET_STOCK_REPORT',
  RESET_STOCK_REPORT: 'rptreport/RESET_STOCK_REPORT',

  GET_STOCK_IN_OUT_REPORT: 'rptreport/GET_STOCK_IN_OUT_REPORT',
  RESET_STOCK_IN_OUT_REPORT: 'rptreport/RESET_STOCK_IN_OUT_REPORT',

  GET_PRODUCT_LEDGER_REPORT: 'rptreport/GET_PRODUCT_LEDGER_REPORT',
  RESET_PRODUCT_LEDGER_REPORT: 'rptreport/RESET_PRODUCT_LEDGER_REPORT',

  GET_BILL_WISE_GROSS_PROFIT_REPORT: 'rptreport/GET_BILL_WISE_GROSS_PROFIT_REPORT',
  RESET_BILL_WISE_GROSS_PROFIT_REPORT: 'rptreport/RESET_BILL_WISE_GROSS_PROFIT_REPORT',

  GET_BILL_TAX_WISE_GROSS_PROFIT_REPORT: 'rptreport/GET_BILL_TAX_WISE_GROSS_PROFIT_REPORT',
  RESET_BILL_TAX_WISE_GROSS_PROFIT_REPORT: 'rptreport/RESET_BILL_TAX_WISE_GROSS_PROFIT_REPORT',

  GET_CASH_BOOK_REPORT: 'rptreport/GET_CASH_BOOK_REPORT',
  RESET_CASH_BOOK_REPORT: 'rptreport/RESET_CASH_BOOK_REPORT',

  GET_CASHIER_BOOK_REPORT: 'rptreport/GET_CASHIER_BOOK_REPORT',
  RESET_CASHIER_BOOK_REPORT: 'rptreport/RESET_CASHIER_BOOK_REPORT',

  GET_PROFIT_LOSS_LIST: 'rptreport/GET_PROFIT_LOSS_LIST',
  RESET_PROFIT_LOSS_LIST: 'rptreport/RESET_PROFIT_LOSS_LIST',

  GET_PURCHASE_REPORT: 'rptreport/GET_PURCHASE_REPORT',
  RESET_PURCHASE_REPORT: 'rptreport/RESET_PURCHASE_REPORT',

  GET_SALE_REPORT: 'rptreport/GET_SALE_REPORT',
  RESET_SALE_REPORT: 'rptreport/RESET_SALE_REPORT',

  GET_PARTY_LIST: 'rptreport/GET_PARTY_LIST',
  RESET_PARTY_LIST: 'rptreport/RESET_PARTY_LIST',
}

export default actions
