import React from 'react'
import { Space } from 'antd'
import moment from 'moment'
import cx from 'classnames'

// ***************************************************
// ******************* ACCOUNT LOOKUP ****************
// ***************************************************
export const ACCOUNT_LOOKUP_TYPE = {
  ALL_CASH_BANK_ACCOUNTS: '1',
}

// ***************************************************
// ******************* USER LOOKUP *******************
// ***************************************************
export const USER_LOOKUP_TYPE = {
  CAPITAL: '1',
  SALARY: '2',
}

// ***************************************************
// ***************** FIXED COA CODES *****************
// ***************************************************
export const COA_CODES = {
  NOA: {
    ASSETS: 1,
    LIABILITY: 2,
    REVENUE: 3,
    EXPENSE: 4,
    EQUITY: 5,
  },
  COA_GROUP: {
    FIXED_ASSETS: 111,
  },
  COA_SUBGROUP: {
    CASH_ACCOUNTS: 11010,
    BANK_ACCOUNTS: 11012,
    ACCOUNT_RECEIVABLES: 11013,
    WALKING_IN_CUSTOMER: 11020,
    PURCHASES: 21018,
    ALL_FIXED_ASSETS: {
      id: 11110,
      title: 'All Fixed Assets',
    },
    ACCOUNT_PAYABLE: 21010,
  },
  ACCOUNT: {
    EXPENSE: {
      DEFAULT_DEPRECIATION_EXPENSE: {
        id: 414101,
        title: 'Default Depreciation Expense',
      },
    },
  },
}

// ***************************************************
// ******************** CONSTANTS ********************
// ***************************************************
export const STATUS_OPTIONS = [
  { value: true, label: 'Enabled' },
  { value: false, label: 'Disabled' },
]

export const DEBIT_CREDIT_OPTIONS = [
  { value: true, label: 'Debit' },
  { value: false, label: 'Credit' },
]

export const DISCOUNT_OPTIONS = [
  { value: 0, label: 'None' },
  { value: 1, label: 'Retailer' },
  { value: 2, label: 'Wholesaler' },
  { value: 3, label: 'Loyalty Card' },
]

export const CONTRA_ACCOUNT_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

export const STOCKHOLDER_STATUS_OPTIONS = [
  { value: true, label: 'Owner/Partner' },
  { value: false, label: 'Investor' },
]

export const STOCKHOLDER_NATURE_OPTIONS = [
  { value: true, label: 'Active' },
  { value: false, label: 'Sleeping' },
]

export const PAYMENT_STATUS_OPTIONS = [
  { value: true, label: 'UN-PAID' },
  { value: false, label: 'PAID' },
]

// ***************************************************
// ******************** CONSTANTS ********************
// ***************************************************
export const VOUCHER_CODES = {
  JournalVoucher: 'JV-',
  CashReceiptVoucher: 'CRV-',
  CashPaymentVoucher: 'CPV-',
  BankReceiptVoucher: 'BRV-',
  BankPaymentVoucher: 'BPV-',
  SalarySlip: 'SS-',
  PurchaseInvoice: 'PI-',
  PurchaseQuote: 'PQ-',
  PurchaseInvoiceTax: 'PIT-',
  PurchaseQuoteTax: 'PQT-',
  PurchaseReturnInvoice: 'PRI-',
  PurchaseReturnInvoiceTax: 'PRIT-',
  SaleInvoice: 'SI-',
  SaleQuote: 'SQ-',
  SaleInvoiceTax: 'SIT-',
  SaleQuoteTax: 'SQT-',
  SaleReturnInvoice: 'SRI-',
  SaleReturnInvoiceTax: 'SRIT-',
  DeliveryNotes: 'DN-',
  GoodsReceipt: 'GR-',
  PurchaseOrder: 'PO-',
  SaleOrder: 'SO-',
}

// ***************************************************
// ******************** FUNCTIONS ********************
// ***************************************************
export const isDefaultRegSearchParamsEmpty = formValues => {
  const { search, createdBy, createdAt, status, selectedSBULookup } = formValues

  return !search && !createdBy?.value && !createdAt && status === undefined && !selectedSBULookup
}

export const getDefaultRegSearchParams = formValues => {
  const { search, createdBy, createdAt, sessionAt, status, selectedSBULookup } = formValues
  const created_by = createdBy?.value

  let created_at
  if (createdAt) {
    // 2020-11-14 05:10:00 :: 2020-11-14 05:10:50
    created_at = `${createdAt[0].format('YYYY-MM-DD HH:mm:ss')} :: ${createdAt[1].format(
      'YYYY-MM-DD HH:mm:ss',
    )}`
  }

  let session_at
  if (sessionAt) {
    // 2020-11-14 :: 2020-11-14
    session_at = `${sessionAt[0].format('YYYY-MM-DD')} :: ${sessionAt[1].format('YYYY-MM-DD')}`
  }

  return { search, created_by, created_at, session_at, status, selectedSBULookup }
}

export const getDefaultRegColumns = (
  momentFormatColumn,
  recordEditHandler,
  openDetailsModalHandler,
  extraColumns = [],
) => {
  return [
    {
      title: <strong>Title</strong>,
      dataIndex: 'title',
      width: 250,
      ellipsis: true,
      render: value => <strong>{value}</strong>,
    },

    ...extraColumns,

    {
      title: 'Remarks',
      dataIndex: 'remarks',
      // width: 275,
      ellipsis: true,
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      width: 150,
      render: value => moment(value).format(momentFormatColumn),
      sorter: (a, b) => moment(a.created_at).diff(b.created_at),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      className: 'text-center',
      render: value => (
        <span
          className={cx('font-size-12', 'badge', {
            'badge-primary': value,
            'badge-default': !value,
          })}
        >
          {value ? 'Enabled' : 'Disabled'}
        </span>
      ),
      sorter: (a, b) => {
        if (a.status === b.status) return 0
        if (a.status) return -1
        return 1
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 170,
      fixed: 'right',
      className: 'text-center',
      render: (_, record) => (
        <Space>
          {/* <Tooltip title="Edit"> */}
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              recordEditHandler(record.id)
            }}
            className="btn btn-sm btn-light"
          >
            <i className="fe fe-edit mr-1" /> Edit
          </a>
          {/* </Tooltip> */}

          {/* <Tooltip title="Details"> */}
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              openDetailsModalHandler(record.id)
            }}
            className="btn btn-sm btn-light"
          >
            <i className="fe fe-archive mr-1" /> Details
          </a>
          {/* </Tooltip> */}
        </Space>
      ),
    },
  ]
}

// ***************************************************
// ********************** OTHER **********************
// ***************************************************
export const DEFAULT_PROFILE_IMAGE = 'resources/images/avatars/default_profile_image.png'
export const DEFAULT_LOGO_IMAGE = 'resources/images/avatars/default_companylogo.jpg'

// ***************************************************
// ****************** SALARY PERIOD ******************
// ***************************************************
export const SALARY_PERIOD = {
  Monthly: 3,
  Daily: 2,
  Hourly: 1,
}

// ***************************************************
// ****************** PAYMENT STATUS ******************
// ***************************************************
export const PAYMENT_STATUS = {
  UN_PAID: 1,
  PAID: 2,
}

export const STOCK_MOVEMENT_METHODS = [
  { value: 'FIFO', label: 'FIFO' },
  { value: 'LIFO', label: 'LIFO' },
  { value: 'AVG', label: 'WEIGHTED AVERAGE' },
]

export const SYSTEM_CONFIGRATION = {
  STOCK_MOVEMENT: 'stock_movement',
  DEFAULT_CUSTOMERS: 'default_customer',
  DEPRECIATION_METHOD: 'depreciation_method',
  DEPRECIATION_PERIOD: 'depreciation_period',
  DISTRIBUTION_PROFIT: 'distribution_profit_method',
}
