import { combineReducers } from 'redux'

import stock from './stock/reducers'
import report from './report/reducers'
import dayEnd from './day-end/reducers'
import dayEndReport from './day-end-report/reducers'

export default combineReducers({
  stock,
  report,
  dayEnd,
  dayEndReport,
})
