import { all } from 'redux-saga/effects'

import stock from './stock/sagas'
import report from './report/sagas'
import dayend from './day-end/sagas'
import dayendreport from './day-end-report/sagas'

export default function* rootSaga() {
  yield all([stock(), report(), dayend(), dayendreport()])
}
