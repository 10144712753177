const actions = {
  SET_STATE: 'commission/SET_STATE',

  ADD_COMMISSION: 'commission/ADD_COMMISSION',
  UPDATE_COMMISSION: 'commission/UPDATE_COMMISSION',
  DELETE_COMMISSION: 'commission/DELETE_COMMISSION',

  GET_LIST: 'commission/GET_LIST',
  RESET_LIST: 'commission/RESET_LIST',

  GET_EDITABLE: 'commission/GET_EDITABLE',
  RESET_EDITABLE: 'commission/RESET_EDITABLE',

  SET_INVOICE_TYPE: 'commission/SET_INVOICE_TYPE',
  RESET_INVOICE_TYPE: 'commission/RESET_INVOICE_TYPE',

  GET_USERS_LIST: 'commission/GET_USERS_LIST',
  PAY_COMMISSION_AMOUNT: 'commission/PAY_COMMISSION_AMOUNT',
  GET_CUSTOMER_LEDGER: 'commission/GET_CUSTOMER_LEDGER',
  RESET_CUSTOMER_LEDGER: 'commission/RESET_CUSTOMER_LEDGER',
}

export default actions
