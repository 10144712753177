const actions = {
  SET_STATE: 'pq/SET_STATE',

  ADD: 'pq/ADD',
  UPDATE: 'pq/UPDATE',
  DELETE: 'pq/DELETE',

  GET_LIST: 'pq/GET_LIST',
  RESET_LIST: 'pq/RESET_LIST',
  GET_NEXT_NO: 'pq/NEXT_NO',
  GET_EDITABLE: 'pq/GET_EDITABLE',
  RESET_EDITABLE: 'pq/RESET_EDITABLE',

  GET_DETAILS: 'pq/GET_DETAILS',
  RESET_DETAILS: 'pq/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'pi/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'pi/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'pi/GET_LOOKUPS',
  // RESET_LOOKUPS: 'pi/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'pq/RESET_SAVING_STATUS',

  GET_PREV_NEXT_IDS: 'pq/GET_PREV_NEXT_IDS',
}

export default actions
