import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as httpService from 'services/http'
import actions from './actions'

const endpoint = '/commissions_resource'

export function* ADD_COMMISSION({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.addItem, endpoint, item)
  if (response) {
    notification.success({
      message: 'Commission Added',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE_COMMISSION({ payload }) {
  const { id, item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.updateItem, endpoint, id, item)
  if (response) {
    notification.success({
      message: 'Commission Updated',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE_COMMISSION({ payload }) {
  const { id } = payload
  const { list, totalRecords } = yield select(state => state.registration.commissionResource)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: list.filter(item => item.id !== id),
    },
  })
  const response = yield call(httpService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        totalRecords: Math.max(0, totalRecords - 1),
      },
    })
    notification.success({
      message: 'Commission Removed',
      description: 'Status: Successful',
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* SET_INVOICE_TYPE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/invoice_list`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoices: response,
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoices: [],
        savingStatus: '',
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.commissions,
        totalRecords: response.data.total_records,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: [],
      totalRecords: 0,
    },
  })
}

export function* GET_EDITABLE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingEditable: true,
    },
  })
  const response = yield call(httpService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        editable: response.data.commissions,
        isLoadingEditable: false,
      },
    })
  } else if (response && response.code === 404) {
    const { list, totalRecords } = yield select(state => state.registration.commissionResource)
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: list.filter(item => item.id !== id),
        totalRecords: Math.max(0, totalRecords - 1),
        isLoadingEditable: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingEditable: false,
      },
    })
  }
}

export function* RESET_EDITABLE() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      editable: {},
    },
  })
}

export function* GET_USERS_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/users_list`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        users: response.data.users,
        totalRecords: response.data.totalRecords,
        savingStatus: 'success',
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        users: [],
        totalRecords: 0,
        savingStatus: '',
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_INVOICE_TYPE(){
  yield put({
    type: actions.SET_STATE,
    payload: {
      invoices: [],
      savingStatus: '',
    },
  })
}

export function* PAY_COMMISSION_AMOUNT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.addItem, `${endpoint}/pay_commission_amount`, payload)
  if (response) {
    notification.success({
      message: 'Commission Amount Paid',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* GET_CUSTOMER_LEDGER({ payload }) {
  const { code } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingEditable: true,
    },
  })
  const response = yield call(httpService.getItemById, `${endpoint}/customer_ledger`, code)
  if (response && response.code === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        userLedger: response.data.commission_tracking,
        userSummary: response.data.commission_user,
        isLoadingEditable: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingEditable: false,
      },
    })
  }
}

export function* RESET_CUSTOMER_LEDGER(){
  yield put({
    type: actions.SET_STATE,
    payload: {
      userLedger: [],
      userSummary: [],
      isLoadingEditable: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_COMMISSION, ADD_COMMISSION),
    takeEvery(actions.UPDATE_COMMISSION, UPDATE_COMMISSION),
    takeEvery(actions.DELETE_COMMISSION, DELETE_COMMISSION),
    takeLatest(actions.GET_LIST, GET_LIST),
    takeLatest(actions.RESET_LIST, RESET_LIST),
    takeLatest(actions.GET_EDITABLE, GET_EDITABLE),
    takeLatest(actions.RESET_EDITABLE, RESET_EDITABLE),
    takeLatest(actions.SET_INVOICE_TYPE, SET_INVOICE_TYPE),
    takeLatest(actions.RESET_INVOICE_TYPE, RESET_INVOICE_TYPE),
    takeLatest(actions.GET_USERS_LIST, GET_USERS_LIST),
    takeLatest(actions.PAY_COMMISSION_AMOUNT, PAY_COMMISSION_AMOUNT),
    takeLatest(actions.GET_CUSTOMER_LEDGER, GET_CUSTOMER_LEDGER),
    takeLatest(actions.RESET_CUSTOMER_LEDGER, RESET_CUSTOMER_LEDGER)
  ])
}
