import { combineReducers } from 'redux'

import pq from './purchase-quotes/purchase-quote/reducers'
import pqt from './purchase-quotes/purchase-tax-quote/reducers'
import sq from './sale-quotes/sale-quote/reducers'
import sqt from './sale-quotes/sale-tax-quote/reducers'

export default combineReducers({
  pq,
  pqt,
  sq,
  sqt,
})
