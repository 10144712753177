import actions from './actions'

const initialState = {
  openingTrialBalance: [],
  closingTrialBalance: [],
  trialBalanceData: [],
  incomeStatementData: {},
  balanceSheetData: {},
  totalDebit: 0,
  totalCredit: 0,
  isLoadingList: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
