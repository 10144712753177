const actions = {
  SET_STATE: 'pit/SET_STATE',

  ADD: 'pit/ADD',
  UPDATE: 'pit/UPDATE',
  DELETE: 'pit/DELETE',

  GET_LIST: 'pit/GET_LIST',
  RESET_LIST: 'pit/RESET_LIST',
  GET_NEXT_NO: 'pit/NEXT_NO',
  GET_EDITABLE: 'pit/GET_EDITABLE',
  RESET_EDITABLE: 'pit/RESET_EDITABLE',

  GET_DETAILS: 'pit/GET_DETAILS',
  RESET_DETAILS: 'pit/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'pi/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'pi/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'pi/GET_LOOKUPS',
  // RESET_LOOKUPS: 'pi/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'pit/RESET_SAVING_STATUS',

  GET_PREV_NEXT_IDS: 'pit/GET_PREV_NEXT_IDS',
}

export default actions
