const actions = {
  SET_STATE: 'stockExcess/SET_STATE',

  ADD: 'stockExcess/ADD',

  GET_LIST: 'stockExcess/GET_LIST',
  RESET_LIST: 'stockExcess/RESET_LIST',

  RESET_SAVING_STATUS: 'stockExcess/RESET_SAVING_STATUS',
}

export default actions
