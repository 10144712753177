import { combineReducers } from 'redux'

import account from './account/reducers'
import business from './business/reducers'
import coa from './coa/reducers'
import party from './party/reducers'
import product from './product/reducers'
import general from './general/reducers'
import others from './others/reducers'
import humanResources from './human-resources/reducers'
import commissionResource from './commission-resource/reducers'
import stockResource from './stock-resources/reducers'

export default combineReducers({
  business,
  account,
  coa,
  party,
  product,
  general,
  others,
  humanResources,
  commissionResource,
  stockResource,
})
