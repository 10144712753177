import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as httpService from 'services/http'
import actions from './actions'

const endpoint = '/reports/reports'

export function* GET_TRANSACTIONS_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingTransactionReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/transaction_report`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        transactionReport: response.data.transaction,
        isLoadingTransactionReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingTransactionReport: false,
      },
    })
  }
}

export function* RESET_TRANSACTIONS_REPORT() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      transactionReport: [],
      isLoadingTransactionReport: false,
    },
  })
}

export function* GET_DAILY_VOUCHER_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingDailyVoucherReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/daily_voucher_report`, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        dailyVoucherReport: response.data,
        isLoadingDailyVoucherReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingDailyVoucherReport: false,
      },
    })
  }
}

export function* RESET_DAILY_VOUCHER_REPORT() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      dailyVoucherReport: [],
      isLoadingDailyVoucherReport: false,
    },
  })
}

export function* GET_PRODUCT_WISE_GROSS_PROFIT_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingProductGrossProfitReport: true,
    },
  })
  const response = yield call(
    httpService.getItem,
    `${endpoint}/product_wise_gross_profit_report`,
    payload,
  )
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productGrossProfitReport: response.data.productProfitReport,
        isLoadingProductGrossProfitReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingProductGrossProfitReport: false,
      },
    })
  }
}

export function* RESET_PRODUCT_WISE_GROSS_PROFIT_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      productGrossProfitReport: [],
      isLoadingProductGrossProfitReport: false,
    },
  })
}

export function* GET_GROUP_WISE_SALE_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingGroupWiseSaleReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/group_wise_sale_report`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        groupWiseSaleReport: response.data.groupWiseSaleReport,
        isLoadingGroupWiseSaleReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingGroupWiseSaleReport: false,
      },
    })
  }
}

export function* RESET_GROUP_WISE_SALE_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      groupWiseSaleReport: [],
      isLoadingGroupWiseSaleReport: false,
    },
  })
}

export function* GET_STOCK_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingStockReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/stock_report`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        stockReport: response.data.stockReport,
        isLoadingStockReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingStockReport: false,
      },
    })
  }
}

export function* RESET_STOCK_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      stockReport: [],
      isLoadingStockReport: false,
    },
  })
}

export function* GET_AUDIT_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingAuditReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/audit_report`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        auditReport: response.data.auditReport,
        isLoadingAuditReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingAuditReport: false,
      },
    })
  }
}

export function* RESET_AUDIT_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      auditReport: [],
      isLoadingAuditReport: false,
    },
  })
}

export function* GET_STOCK_IN_OUT_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingStockInOutReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/stock_in_out_report`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        stockInOutReport: response.data.stockInOutReport,
        isLoadingStockInOutReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingStockInOutReport: false,
      },
    })
  }
}

export function* RESET_STOCK_IN_OUT_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      stockInOutReport: [],
      isLoadingStockInOutReport: false,
    },
  })
}

export function* GET_PRODUCT_LEDGER_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingProductLedger: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/product_ledger`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productLedger: response.data.productLedger,
        isLoadingProductLedger: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingProductLedger: false,
      },
    })
  }
}

export function* RESET_PRODUCT_LEDGER_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      productLedger: [],
      isLoadingProductLedger: false,
    },
  })
}

export function* GET_BILL_WISE_GROSS_PROFIT_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingBillWiseGrossProfitReport: true,
    },
  })
  const response = yield call(
    httpService.getItem,
    `${endpoint}/bill_wise_gross_profit_report`,
    payload,
  )
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        billWiseGrossProfitReport: response.data.billWiseGrossProfitReport,
        isLoadingBillWiseGrossProfitReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingBillWiseGrossProfitReport: false,
      },
    })
  }
}

export function* RESET_BILL_WISE_GROSS_PROFIT_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      billWiseGrossProfitReport: [],
      isLoadingBillWiseGrossProfitReport: false,
    },
  })
}

export function* GET_BILL_TAX_WISE_GROSS_PROFIT_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingBillTaxWiseGrossProfitReport: true,
    },
  })
  const response = yield call(
    httpService.getItem,
    `${endpoint}/bill_tax_wise_gross_profit_report`,
    payload,
  )
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        billTaxWiseGrossProfitReport: response.data.billTaxWiseGrossProfitReport,
        isLoadingBillTaxWiseGrossProfitReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingBillTaxWiseGrossProfitReport: false,
      },
    })
  }
}

export function* RESET_BILL_TAX_WISE_GROSS_PROFIT_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      billTaxWiseGrossProfitReport: [],
      isLoadingBillTaxWiseGrossProfitReport: false,
    },
  })
}

export function* GET_CASH_BOOK_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingCashBookReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/cash_book_report`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cashBookReport: response.data.cashBookReport,
        isLoadingCashBookReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingCashBookReport: false,
      },
    })
  }
}

export function* RESET_CASH_BOOK_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      cashBookReport: [],
      isLoadingCashBookReport: false,
    },
  })
}

export function* GET_CASHIER_BOOK_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingCashierReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/cashier_report`, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cashierReport: response.data.cashierReport,
        isLoadingCashierReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingCashierReport: false,
      },
    })
  }
}

export function* RESET_CASHIER_BOOK_REPORT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      cashierReport: [],
      isLoadingCashierReport: false,
    },
  })
}

export function* GET_PROFIT_LOSS_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingProfitLossReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/item_wise_profit_loss`, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        profitLossReport: response.data.products,
        isLoadingProfitLossReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingProfitLossReport: false,
      },
    })
  }
}

export function* RESET_PROFIT_LOSS_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      profitLossReport: [],
      isLoadingProfitLossReport: false,
    },
  })
}

export function* GET_PURCHASE_REPORT({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingPurchaseReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/purchase_report`, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        purchaseReport: response.data.products,
        isLoadingPurchaseReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingPurchaseReport: false,
      },
    })
  }
}

export function* RESET_PURCHASE_REPORT() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      purchaseReport: [],
      isLoadingPurchaseReport: false,
    },
  })
}

export function* GET_SALE_REPORT({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingSaleReport: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/sale_report`, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        saleReport: response.data.products,
        isLoadingSaleReport: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingSaleReport: false,
      },
    })
  }
}

export function* RESET_SALE_REPORT() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      saleReport: [],
      isLoadingSaleReport: false,
    },
  })
}

export function* GET_PARTY_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingPartyList: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/party_list`, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        partyList: response.data.parties,
        isLoadingPartyList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingPartyList: false,
      },
    })
  }
}

export function* RESET_PARTY_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      partyList: [],
      isLoadingPartyList: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_TRANSACTIONS_REPORT, GET_TRANSACTIONS_REPORT),
    takeLatest(actions.RESET_TRANSACTIONS_REPORT, RESET_TRANSACTIONS_REPORT),
    takeLatest(actions.GET_DAILY_VOUCHER_REPORT, GET_DAILY_VOUCHER_REPORT),
    takeLatest(actions.RESET_DAILY_VOUCHER_REPORT, RESET_DAILY_VOUCHER_REPORT),
    takeLatest(actions.GET_PRODUCT_WISE_GROSS_PROFIT_REPORT, GET_PRODUCT_WISE_GROSS_PROFIT_REPORT),
    takeLatest(
      actions.RESET_PRODUCT_WISE_GROSS_PROFIT_REPORT,
      RESET_PRODUCT_WISE_GROSS_PROFIT_REPORT,
    ),
    takeLatest(actions.GET_GROUP_WISE_SALE_REPORT, GET_GROUP_WISE_SALE_REPORT),
    takeLatest(actions.RESET_GROUP_WISE_SALE_REPORT, RESET_GROUP_WISE_SALE_REPORT),
    takeLatest(actions.GET_STOCK_REPORT, GET_STOCK_REPORT),
    takeLatest(actions.RESET_STOCK_REPORT, RESET_STOCK_REPORT),
    takeLatest(actions.GET_AUDIT_REPORT, GET_AUDIT_REPORT),
    takeLatest(actions.RESET_AUDIT_REPORT, RESET_AUDIT_REPORT),
    takeLatest(actions.GET_STOCK_IN_OUT_REPORT, GET_STOCK_IN_OUT_REPORT),
    takeLatest(actions.RESET_STOCK_IN_OUT_REPORT, RESET_STOCK_IN_OUT_REPORT),
    takeLatest(actions.GET_PRODUCT_LEDGER_REPORT, GET_PRODUCT_LEDGER_REPORT),
    takeLatest(actions.RESET_PRODUCT_LEDGER_REPORT, RESET_PRODUCT_LEDGER_REPORT),
    takeLatest(actions.GET_BILL_WISE_GROSS_PROFIT_REPORT, GET_BILL_WISE_GROSS_PROFIT_REPORT),
    takeLatest(actions.RESET_BILL_WISE_GROSS_PROFIT_REPORT, RESET_BILL_WISE_GROSS_PROFIT_REPORT),
    takeLatest(
      actions.GET_BILL_TAX_WISE_GROSS_PROFIT_REPORT,
      GET_BILL_TAX_WISE_GROSS_PROFIT_REPORT,
    ),
    takeLatest(
      actions.RESET_BILL_TAX_WISE_GROSS_PROFIT_REPORT,
      RESET_BILL_TAX_WISE_GROSS_PROFIT_REPORT,
    ),
    takeLatest(actions.GET_CASH_BOOK_REPORT, GET_CASH_BOOK_REPORT),
    takeLatest(actions.RESET_CASH_BOOK_REPORT, RESET_CASH_BOOK_REPORT),
    takeLatest(actions.GET_CASHIER_BOOK_REPORT, GET_CASHIER_BOOK_REPORT),
    takeLatest(actions.RESET_CASHIER_BOOK_REPORT, RESET_CASHIER_BOOK_REPORT),
    takeLatest(actions.GET_PROFIT_LOSS_LIST, GET_PROFIT_LOSS_LIST),
    takeLatest(actions.RESET_PROFIT_LOSS_LIST, RESET_PROFIT_LOSS_LIST),
    takeLatest(actions.GET_PURCHASE_REPORT, GET_PURCHASE_REPORT),
    takeLatest(actions.RESET_PURCHASE_REPORT, RESET_PURCHASE_REPORT),
    takeLatest(actions.GET_SALE_REPORT, GET_SALE_REPORT),
    takeLatest(actions.RESET_SALE_REPORT, RESET_SALE_REPORT),
    takeLatest(actions.GET_PARTY_LIST, GET_PARTY_LIST),
    takeLatest(actions.RESET_PARTY_LIST, RESET_PARTY_LIST),
  ])
}
