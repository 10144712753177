const actions = {
  SET_STATE: 'sit/SET_STATE',

  ADD: 'sit/ADD',
  UPDATE: 'sit/UPDATE',
  DELETE: 'sit/DELETE',

  GET_LIST: 'sit/GET_LIST',
  RESET_LIST: 'sit/RESET_LIST',
  GET_NEXT_NO: 'sit/NEXT_NO',
  GET_EDITABLE: 'sit/GET_EDITABLE',
  RESET_EDITABLE: 'sit/RESET_EDITABLE',

  GET_DETAILS: 'sit/GET_DETAILS',
  RESET_DETAILS: 'sit/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'si/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'si/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'si/GET_LOOKUPS',
  // RESET_LOOKUPS: 'si/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'sit/RESET_SAVING_STATUS',

  GET_PREV_NEXT_IDS: 'sit/GET_PREV_NEXT_IDS',
}

export default actions
