const actions = {
  SET_STATE: 'po/SET_STATE',

  ADD: 'po/ADD',
  UPDATE: 'po/UPDATE',
  DELETE: 'po/DELETE',

  GET_LIST: 'po/GET_LIST',
  RESET_LIST: 'po/RESET_LIST',
  GET_NEXT_NO: 'po/NEXT_NO',
  GET_EDITABLE: 'po/GET_EDITABLE',
  RESET_EDITABLE: 'po/RESET_EDITABLE',

  GET_DETAILS: 'po/GET_DETAILS',
  RESET_DETAILS: 'po/RESET_DETAILS',

  RESET_SAVING_STATUS: 'po/RESET_SAVING_STATUS',

  GET_PREV_NEXT_IDS: 'po/GET_PREV_NEXT_IDS',
}

export default actions
