import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as authJwtService from 'services/auth/jwt'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    login: authJwtService.login,
    authMe: authJwtService.authMe,
    logout: authJwtService.logout,
    // register: authJwtService.register,
    forgotPassword: authJwtService.forgotPassword,
    // updateProfile: authJwtService.updateProfile,
    changePassword: authJwtService.changePassword,
    validateResetPasswordToken: authJwtService.validateResetPasswordToken,
    resetPassword: authJwtService.resetPassword,
    validateWelcomeEmailToken: authJwtService.validateWelcomeEmailToken,
    setupPassword: authJwtService.setupPassword,
  },
}

export function* LOGIN({ payload }) {
  const { email: userName, password } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  console.log(authProvider)
  console.log(mapAuthProviders[authProvider].login)
  console.log(payload)
  console.log(mapAuthProviders)
  const response = yield call(mapAuthProviders[authProvider].login, userName, password)
  if (response) {
    const {
      id,
      employee_code,
      designation,
      email,
      name,
      username,
      role,
      type,
      cell_number,
      emergency_number,
      father_name,
      cnic,
      is_muslim,
      is_married,
      blood_group,
      avatar,
    } = response.user
    yield put({
      type: actions.SET_STATE,
      payload: {
        id,
        employee_code,
        username,
        designation,
        email,
        name,
        role,
        type,
        blood_group,
        emergency_number,
        father_name,
        cnic,
        is_muslim,
        is_married,
        cell_number,
        avatar,
        authorized: true,
        loading: false,
      },
    })
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
  if (!response) {
    notification.error({
      message: 'Logged Failed',
      description: 'Invalid Credentials!',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* AUTH_ME() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].authMe)
  if (response) {
    const {
      id,
      employee_code,
      designation,
      email,
      name,
      username,
      role,
      type,
      cell_number,
      emergency_number,
      father_name,
      cnic,
      is_muslim,
      is_married,
      blood_group,
      avatar,
    } = response.me
    yield put({
      type: actions.SET_STATE,
      payload: {
        id,
        employee_code,
        username,
        designation,
        email,
        name,
        role,
        type,
        blood_group,
        emergency_number,
        father_name,
        cnic,
        is_muslim,
        is_married,
        cell_number,
        avatar,
        authorized: true,
        loading: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      employee_code: '',
      email: '',
      name: '',
      username: '',
      designation: '',
      cell_number: '',
      father_name: '',
      emergency_number: '',
      cnic: '',
      is_married: false,
      is_muslim: false,
      role: {},
      type: {},
      blood_group: {},
      avatar: '',
      authorized: false,
      loading: false,
      savingStatus: '',
    },
  })
}

// export function* REGISTER({ payload }) {
//   const { email, password, name } = payload
//   yield put({
//     type: authActions.SET_STATE,
//     payload: {
//       loading: true,
//     },
//   })
//   const { authProvider } = yield select(state => state.settings)
//   const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
//   if (success) {
//     yield put({
//       type: authActions.LOAD_ME,
//     })
//     yield history.push('/')
//     notification.success({
//       message: 'Successful Registered',
//       description: 'You have successfully registered!',
//     })
//   }
//   if (!success) {
//     yield put({
//       type: authActions.SET_STATE,
//       payload: {
//         loading: false,
//       },
//     })
//   }
// }

export function* FORGOT_PASSWORD({ payload }) {
  const { email } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].forgotPassword, email)
  if (success) {
    notification.success({
      message: 'Password Reset Email Sent',
      description: 'Please check your inbox to reset password.',
    })
    yield history.replace('/auth/login')
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_PROFILE({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].updateProfile, {
    ...item,
    blood_group: undefined,
  })
  if (success) {
    notification.success({
      message: 'Profile Updated',
      description: 'Your profile is updated',
    })
    const {
      name,
      father_name,
      cell_number,
      emergency_number,
      cnic,
      is_married,
      is_muslim,
      blood_group,
    } = item
    yield put({
      type: actions.SET_STATE,
      payload: {
        name,
        father_name,
        cell_number,
        emergency_number,
        cnic,
        is_married,
        is_muslim,
        blood_group: blood_group ? { id: blood_group.value, title: blood_group.label } : undefined,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CHANGE_AVATAR({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      avatar: payload.location,
    },
  })
}

export function* CHANGE_PASSWORD({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      savingStatus: 'saving',
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].changePassword, item)
  if (success) {
    notification.success({
      message: 'Password Changed',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        savingStatus: '', // failed
      },
    })
  }
}

export function* VALIDATE_RESET_PASSWORD_TOKEN({ payload }) {
  const { token } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      spinning: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].validateResetPasswordToken, token)
  if (response) {
    if (response.code === 401) {
      yield history.replace({
        pathname: '/auth/error',
        state: { error: 'Expired', message: response.description },
      })
    }
    // notification.success({
    //   message: 'Password Reset',
    //   description: 'Your password reset successfully',
    // })
  } else {
    notification.error({
      message: 'Invalid request',
      description: 'Link expired or not valid',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      spinning: false,
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  const { token, new_password } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].resetPassword, token, new_password)
  if (response) {
    if (response.code === 401) {
      yield history.replace({
        pathname: '/auth/error',
        state: { error: 'Expired', message: response.description },
      })
    } else {
      yield history.replace('/auth/login')
      notification.success({
        message: 'Password Reset',
        description: 'Your password reset successfully',
      })
    }
  } else {
    notification.error({
      message: 'Invalid request',
      description: 'Link expired or not valid',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* VALIDATE_WELCOME_EMAIL_TOKEN({ payload }) {
  const { token } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      spinning: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].validateWelcomeEmailToken, token)
  if (response) {
    if (response.code === 401) {
      yield history.replace({
        pathname: '/auth/error',
        state: { error: 'Expired', message: response.description },
      })
    }
    // notification.success({
    //   message: 'Password Reset',
    //   description: 'Your password reset successfully',
    // })
  } else {
    notification.error({
      message: 'Invalid request',
      description: 'Link expired or not valid',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      spinning: false,
    },
  })
}

export function* SETUP_PASSWORD({ payload }) {
  const { token, new_password } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].setupPassword, token, new_password)
  if (response) {
    if (response.code === 401) {
      yield history.replace({
        pathname: '/auth/error',
        state: { error: 'Expired', message: response.description },
      })
    } else {
      yield history.replace('/auth/login')
      notification.success({
        message: 'Password Set',
        description: 'Your password is setup successfully',
      })
    }
  } else {
    notification.error({
      message: 'Invalid request',
      description: 'Link expired or not valid',
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.AUTH_ME, AUTH_ME),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.CHANGE_AVATAR, CHANGE_AVATAR),
    // takeEvery(authActions.REGISTER, REGISTER),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.VALIDATE_RESET_PASSWORD_TOKEN, VALIDATE_RESET_PASSWORD_TOKEN),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.VALIDATE_WELCOME_EMAIL_TOKEN, VALIDATE_WELCOME_EMAIL_TOKEN),
    takeEvery(actions.SETUP_PASSWORD, SETUP_PASSWORD),
    AUTH_ME(), // run once on app load to check user auth
  ])
}
