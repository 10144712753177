import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'
import FallbackSpinner from 'components/custom/Spinners/FallbackSpinner'

const routes = [
  // ******************** DASHBOARDS ********************
  {
    path: '/dashboard/alpha',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },

  // ******************** REGISTRATION ********************
  // =============== Business Registration ===============
  {
    path: '/registration/business/company',
    Component: lazy(() => import('pages/registration/business/company')),
    exact: true,
  },
  {
    path: '/registration/business/sbu',
    Component: lazy(() => import('pages/registration/business/sbu')),
    exact: true,
  },
  {
    path: '/registration/business/sbu-list',
    Component: lazy(() => import('pages/registration/business/sbu-list')),
    exact: true,
  },
  // =============== Account Registration ===============
  {
    path: '/registration/account/account-access-group',
    Component: lazy(() => import('pages/registration/account/aag')),
    exact: true,
  },
  {
    path: '/registration/account/account-access-group-list',
    Component: lazy(() => import('pages/registration/account/aag-list')),
    exact: true,
  },
  {
    path: '/registration/account/account',
    Component: lazy(() => import('pages/registration/account/account')),
    exact: true,
  },
  {
    path: '/registration/account/account-list',
    Component: lazy(() => import('pages/registration/account/account-list')),
    exact: true,
  },
  {
    path: '/registration/account/account-ledger-list',
    Component: lazy(() => import('pages/registration/account/account-ledger-list')),
    exact: true,
  },
  {
    path: '/registration/account/bank-account',
    Component: lazy(() => import('pages/registration/account/bank-account')),
    exact: true,
  },
  {
    path: '/registration/account/bank-account-list',
    Component: lazy(() => import('pages/registration/account/bank-account-list')),
    exact: true,
  },
  {
    path: '/registration/account/fixed-asset',
    Component: lazy(() => import('pages/registration/account/fixed-asset')),
    exact: true,
  },
  {
    path: '/registration/account/fixed-asset-list',
    Component: lazy(() => import('pages/registration/account/fixed-asset-list')),
    exact: true,
  },
  {
    path: '/registration/account/fixed-asset-sale',
    Component: lazy(() => import('pages/registration/account/fixed-asset-sale')),
    exact: true,
  },
  {
    path: '/registration/account/fixed-asset-sale-list',
    Component: lazy(() => import('pages/registration/account/fixed-asset-sale-list')),
    exact: true,
  },
  {
    path: '/registration/account/capital',
    Component: lazy(() => import('pages/registration/account/capital-account')),
    exact: true,
  },
  {
    path: '/registration/account/capital-list',
    Component: lazy(() => import('pages/registration/account/capital-account-list')),
    exact: true,
  },
  {
    path: '/registration/account/opening',
    Component: lazy(() => import('pages/registration/account/opening')),
    exact: true,
  },

  // =============== Chart Of Accounts ===============
  {
    path: '/registration/coa/chart-of-accounts',
    Component: lazy(() => import('pages/registration/coa/chart-of-accounts')),
    exact: true,
  },
  {
    path: '/registration/coa/group',
    Component: lazy(() => import('pages/registration/coa/coa-group')),
    exact: true,
  },
  {
    path: '/registration/coa/group-list',
    Component: lazy(() => import('pages/registration/coa/coa-group-list')),
    exact: true,
  },
  {
    path: '/registration/coa/subgroup',
    Component: lazy(() => import('pages/registration/coa/coa-subgroup')),
    exact: true,
  },
  {
    path: '/registration/coa/subgroup-list',
    Component: lazy(() => import('pages/registration/coa/coa-subgroup-list')),
    exact: true,
  },

  // =============== Party Registration ===============
  {
    path: '/registration/party/region',
    Component: lazy(() => import('pages/registration/party/region')),
    exact: true,
  },
  {
    path: '/registration/party/region-list',
    Component: lazy(() => import('pages/registration/party/region-list')),
    exact: true,
  },
  {
    path: '/registration/party/area',
    Component: lazy(() => import('pages/registration/party/area')),
    exact: true,
  },
  {
    path: '/registration/party/area-list',
    Component: lazy(() => import('pages/registration/party/area-list')),
    exact: true,
  },
  {
    path: '/registration/party/sector',
    Component: lazy(() => import('pages/registration/party/sector')),
    exact: true,
  },
  {
    path: '/registration/party/sector-list',
    Component: lazy(() => import('pages/registration/party/sector-list')),
    exact: true,
  },
  {
    path: '/registration/party/client',
    Component: lazy(() => import('pages/registration/party/client')),
    exact: true,
  },
  {
    path: '/registration/party/client-list',
    Component: lazy(() => import('pages/registration/party/client-list')),
    exact: true,
  },
  {
    path: '/registration/party/supplier',
    Component: lazy(() => import('pages/registration/party/supplier')),
    exact: true,
  },
  {
    path: '/registration/party/supplier-list',
    Component: lazy(() => import('pages/registration/party/supplier-list')),
    exact: true,
  },

  // =============== Product Registration ===============
  {
    path: '/registration/product/main-unit',
    Component: lazy(() => import('pages/registration/product/main-unit')),
    exact: true,
  },
  {
    path: '/registration/product/main-unit-list',
    Component: lazy(() => import('pages/registration/product/main-unit-list')),
    exact: true,
  },
  {
    path: '/registration/product/unit',
    Component: lazy(() => import('pages/registration/product/unit')),
    exact: true,
  },
  {
    path: '/registration/product/unit-list',
    Component: lazy(() => import('pages/registration/product/unit-list')),
    exact: true,
  },
  {
    path: '/registration/product/group',
    Component: lazy(() => import('pages/registration/product/product-group')),
    exact: true,
  },
  {
    path: '/registration/product/group-list',
    Component: lazy(() => import('pages/registration/product/product-group-list')),
    exact: true,
  },
  {
    path: '/registration/product/category',
    Component: lazy(() => import('pages/registration/product/product-category')),
    exact: true,
  },
  {
    path: '/registration/product/category-list',
    Component: lazy(() => import('pages/registration/product/product-category-list')),
    exact: true,
  },
  {
    path: '/registration/product/sub-category',
    Component: lazy(() => import('pages/registration/product/product-sub-category')),
    exact: true,
  },
  {
    path: '/registration/product/sub-category-list',
    Component: lazy(() => import('pages/registration/product/product-sub-category-list')),
    exact: true,
  },
  {
    path: '/registration/product/brand',
    Component: lazy(() => import('pages/registration/product/product-brand')),
    exact: true,
  },
  {
    path: '/registration/product/brand-list',
    Component: lazy(() => import('pages/registration/product/product-brand-list')),
    exact: true,
  },
  {
    path: '/registration/product/model',
    Component: lazy(() => import('pages/registration/product/product-model')),
    exact: true,
  },
  {
    path: '/registration/product/model-list',
    Component: lazy(() => import('pages/registration/product/product-model-list')),
    exact: true,
  },
  {
    path: '/registration/product/product',
    Component: lazy(() => import('pages/registration/product/product')),
    exact: true,
  },
  {
    path: '/registration/product/product-list',
    Component: lazy(() => import('pages/registration/product/product-list')),
    exact: true,
  },
  {
    path: '/registration/product/package-group',
    Component: lazy(() => import('pages/registration/product/package-group')),
    exact: true,
  },
  {
    path: '/registration/product/package-group-list',
    Component: lazy(() => import('pages/registration/product/package-group-list')),
    exact: true,
  },
  {
    path: '/registration/product/package',
    Component: lazy(() => import('pages/registration/product/package')),
    exact: true,
  },
  {
    path: '/registration/product/package-list',
    Component: lazy(() => import('pages/registration/product/package-list')),
    exact: true,
  },
  {
    path: '/registration/product/opening',
    Component: lazy(() => import('pages/registration/product/opening')),
    exact: true,
  },
  // =============== General Registration ===============
  {
    path: '/registration/general/warehouse',
    Component: lazy(() => import('pages/registration/general/warehouse')),
    exact: true,
  },
  {
    path: '/registration/general/warehouse-list',
    Component: lazy(() => import('pages/registration/general/warehouse-list')),
    exact: true,
  },
  {
    path: '/registration/general/service',
    Component: lazy(() => import('pages/registration/general/service')),
    exact: true,
  },
  {
    path: '/registration/general/service-list',
    Component: lazy(() => import('pages/registration/general/service-list')),
    exact: true,
  },

  // =============== Human Resources ===============
  {
    path: '/registration/human-resources/user',
    Component: lazy(() => import('pages/registration/human-resources/user')),
    exact: true,
  },
  {
    path: '/registration/human-resources/user-list',
    Component: lazy(() => import('pages/registration/human-resources/user-list')),
    exact: true,
  },
  {
    path: '/user/profile',
    Component: lazy(() => import('pages/registration/human-resources/user/profile')),
    exact: true,
  },
  {
    path: '/registration/human-resources/salary-user',
    Component: lazy(() => import('pages/registration/human-resources/salary-user')),
    exact: true,
  },
  {
    path: '/registration/human-resources/department-list',
    Component: lazy(() => import('pages/registration/human-resources/department-list')),
    exact: true,
  },
  {
    path: '/registration/human-resources/department',
    Component: lazy(() => import('pages/registration/human-resources/department')),
    exact: true,
  },
  {
    path: '/registration/human-resources/salary-user-list',
    Component: lazy(() => import('pages/registration/human-resources/salary-user-list')),
    exact: true,
  },

  // =============== Commission Resources ===============
  // {
  //   path: '/registration/commission-resources/commission',
  //   Component: lazy(() => import('pages/registration/commission-resources/commission')),
  //   exact: true,
  // },
  {
    path: '/registration/commission-resources/commission-list',
    Component: lazy(() => import('pages/registration/commission-resources/commission-list')),
    exact: true,
  },
  {
    path: '/registration/commission-resources/user-list',
    Component: lazy(() => import('pages/registration/commission-resources/user-list')),
    exact: true,
  },

  // =============== Stock Management Resource ===============
  {
    path: '/registration/stock-resource/stock-excess',
    Component: lazy(() => import('pages/registration/stock-resources/stock-excess')),
    exact: true,
  },
  {
    path: '/registration/stock-resource/stock-write-off',
    Component: lazy(() => import('pages/registration/stock-resources/stock-write-off')),
    exact: true,
  },

  // =============== System Preferences ===============
  {
    path: '/system/user-list',
    Component: lazy(() => import('pages/setting/user-list')),
    exact: true,
  },
  {
    path: '/system/sbu-users',
    Component: lazy(() => import('pages/setting/sbu-users')),
    exact: true,
  },
  {
    path: '/system/shareholder-list',
    Component: lazy(() => import('pages/setting/shareholder-list')),
    exact: true,
  },
  {
    path: '/system/system-setting',
    Component: lazy(() => import('pages/setting/system-setting')),
    exact: true,
  },

  // ========================= Cash Vouchers ========================
  {
    path: '/vouchers/cash-receipt-voucher',
    Component: lazy(() => import('pages/vouchers/cash-vouchers/receipt-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/cash-receipt-voucher-list',
    Component: lazy(() => import('pages/vouchers/cash-vouchers-list/receipt-voucher-list')),
    exact: true,
  },
  {
    path: '/vouchers/cash-payment-voucher',
    Component: lazy(() => import('pages/vouchers/cash-vouchers/payment-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/cash-payment-voucher-list',
    Component: lazy(() => import('pages/vouchers/cash-vouchers-list/payment-voucher-list')),
    exact: true,
  },

  // ========================= Bank Vouchers ========================
  {
    path: '/vouchers/bank-receipt-voucher',
    Component: lazy(() => import('pages/vouchers/bank-vouchers/receipt-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/bank-receipt-voucher-list',
    Component: lazy(() => import('pages/vouchers/bank-vouchers-list/receipt-voucher-list')),
    exact: true,
  },
  {
    path: '/vouchers/bank-payment-voucher',
    Component: lazy(() => import('pages/vouchers/bank-vouchers/payment-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/bank-payment-voucher-list',
    Component: lazy(() => import('pages/vouchers/bank-vouchers-list/payment-voucher-list')),
    exact: true,
  },

  // ========================= Vouchers ========================
  {
    path: '/vouchers/jv',
    Component: lazy(() => import('pages/vouchers/journal-vouchers')),
    exact: true,
  },

  {
    path: '/vouchers/jv-list',
    Component: lazy(() => import('pages/vouchers/journal-vouchers-list')),
    exact: true,
  },

  // ========================= Salary Vouchers ========================
  {
    path: '/vouchers/salary-slip',
    Component: lazy(() => import('pages/vouchers/salary-vouchers/slip')),
    exact: true,
  },

  {
    path: '/vouchers/salary-slip-list',
    Component: lazy(() => import('pages/vouchers/salary-vouchers/slip-list')),
    exact: true,
  },

  // ========================= Purchase Quotes ========================
  {
    path: '/quotes/purchase-quote',
    Component: lazy(() => import('pages/quotes/purchases/purchase-quote')),
    exact: true,
  },
  {
    path: '/quotes/purchase-tax-quote',
    Component: lazy(() => import('pages/quotes/purchases/purchase-tax-quote')),
    exact: true,
  },
  {
    path: '/quotes/purchase-quote-list',
    Component: lazy(() => import('pages/quotes/purchases/purchase-quote-list')),
    exact: true,
  },
  {
    path: '/quotes/purchase-tax-quote-list',
    Component: lazy(() => import('pages/quotes/purchases/purchase-tax-quote-list')),
    exact: true,
  },

  // ========================= Sale Quotes ========================
  {
    path: '/quotes/sale-quote',
    Component: lazy(() => import('pages/quotes/sales/sale-quote')),
    exact: true,
  },
  {
    path: '/quotes/sale-tax-quote',
    Component: lazy(() => import('pages/quotes/sales/sale-tax-quote')),
    exact: true,
  },
  {
    path: '/quotes/sale-quote-list',
    Component: lazy(() => import('pages/quotes/sales/sale-quote-list')),
    exact: true,
  },
  {
    path: '/quotes/sale-tax-quote-list',
    Component: lazy(() => import('pages/quotes/sales/sale-tax-quote-list')),
    exact: true,
  },

  // ========================= Purchase Invoices ========================
  {
    path: '/invoices/purchase-invoice',
    Component: lazy(() => import('pages/invoices/purchases/purchase-invoice')),
    exact: true,
  },
  {
    path: '/invoices/purchase-tax-invoice',
    Component: lazy(() => import('pages/invoices/purchases/purchase-tax-invoice')),
    exact: true,
  },
  {
    path: '/invoices/purchase-invoice-list',
    Component: lazy(() => import('pages/invoices/purchases/purchase-invoice-list')),
    exact: true,
  },
  {
    path: '/invoices/purchase-tax-invoice-list',
    Component: lazy(() => import('pages/invoices/purchases/purchase-tax-invoice-list')),
    exact: true,
  },
  {
    path: '/invoices/purchase-return-invoice',
    Component: lazy(() => import('pages/invoices/purchases/purchase-return-invoice')),
    exact: true,
  },
  {
    path: '/invoices/purchase-tax-return-invoice',
    Component: lazy(() => import('pages/invoices/purchases/purchase-tax-return-invoice')),
    exact: true,
  },
  {
    path: '/invoices/purchase-return-invoice-list',
    Component: lazy(() => import('pages/invoices/purchases/purchase-return-invoice-list')),
    exact: true,
  },
  {
    path: '/invoices/purchase-tax-return-invoice-list',
    Component: lazy(() => import('pages/invoices/purchases/purchase-tax-return-invoice-list')),
    exact: true,
  },

  // ========================= Sale Invoices ========================
  {
    path: '/invoices/sale-invoice',
    Component: lazy(() => import('pages/invoices/sales/sale-invoice')),
    exact: true,
  },
  {
    path: '/invoices/sale-tax-invoice',
    Component: lazy(() => import('pages/invoices/sales/sale-tax-invoice')),
    exact: true,
  },
  {
    path: '/invoices/sale-invoice-list',
    Component: lazy(() => import('pages/invoices/sales/sale-invoice-list')),
    exact: true,
  },
  {
    path: '/invoices/sale-tax-invoice-list',
    Component: lazy(() => import('pages/invoices/sales/sale-tax-invoice-list')),
    exact: true,
  },

  {
    path: '/invoices/sale-return-invoice',
    Component: lazy(() => import('pages/invoices/sales/sale-return-invoice')),
    exact: true,
  },
  {
    path: '/invoices/sale-tax-return-invoice',
    Component: lazy(() => import('pages/invoices/sales/sale-tax-return-invoice')),
    exact: true,
  },
  {
    path: '/invoices/sale-return-invoice-list',
    Component: lazy(() => import('pages/invoices/sales/sale-return-invoice-list')),
    exact: true,
  },
  {
    path: '/invoices/sale-tax-return-invoice-list',
    Component: lazy(() => import('pages/invoices/sales/sale-tax-return-invoice-list')),
    exact: true,
  },

  // ========================= Orders ========================
  {
    path: '/orders/purchase-order-list',
    Component: lazy(() => import('pages/orders/purchase/purchase-order-list')),
    exact: true,
  },
  {
    path: '/orders/purchase-order',
    Component: lazy(() => import('pages/orders/purchase/purchase-order')),
    exact: true,
  },
  {
    path: '/orders/sale-order-list',
    Component: lazy(() => import('pages/orders/sale/sale-order-list')),
    exact: true,
  },
  {
    path: '/orders/sale-order',
    Component: lazy(() => import('pages/orders/sale/sale-order')),
    exact: true,
  },

  // ========================= Notes & Receipts ========================
  {
    path: '/notes/delivery-notes-list',
    Component: lazy(() => import('pages/notes/delivery-notes-list')),
    exact: true,
  },
  {
    path: '/notes/delivery-notes',
    Component: lazy(() => import('pages/notes/delivery-notes')),
    exact: true,
  },
  {
    path: '/notes/goods-receipt-list',
    Component: lazy(() => import('pages/notes/goods-receipt-list')),
    exact: true,
  },
  {
    path: '/notes/goods-receipt',
    Component: lazy(() => import('pages/notes/goods-receipt')),
    exact: true,
  },

  // ========================= Financial Reports ========================
  {
    path: '/reports/financial/accounts-list',
    Component: lazy(() => import('pages/reports/financial/accountlist')),
    exact: true,
  },
  {
    path: '/reports/financial/day-end',
    Component: lazy(() => import('pages/reports/financial/day-end')),
    exact: true,
  },
  {
    path: '/reports/financial/day-end-report',
    Component: lazy(() => import('pages/reports/financial/day-end-report')),
    exact: true,
  },
  // ========================= Stock Reports ========================
  {
    path: '/reports/stock/products-list',
    Component: lazy(() => import('pages/reports/stock/productlist')),
    exact: true,
  },
  {
    path: '/reports/stock/stock-flow',
    Component: lazy(() => import('pages/reports/stock/stockflow')),
    exact: true,
  },
  // {
  //   path: '/reports/stock/on-hand-stock-report',
  //   Component: lazy(() => import('pages/reports/stock/onhandstock')),
  //   exact: true,
  // },
  {
    path: '/reports/report/audit-report',
    Component: lazy(() => import('pages/reports/reports/auditreport')),
    exact: true,
  },
  {
    path: '/reports/report/transaction-report',
    Component: lazy(() => import('pages/reports/reports/transactionreport')),
    exact: true,
  },
  {
    path: '/reports/report/daily-voucher-report',
    Component: lazy(() => import('pages/reports/reports/dailyvoucherreport')),
    exact: true,
  },
  {
    path: '/reports/report/product-wise-gross-profit-report',
    Component: lazy(() => import('pages/reports/reports/productwisegrossprofitreport')),
    exact: true,
  },
  {
    path: '/reports/report/group-wise-sale-report',
    Component: lazy(() => import('pages/reports/reports/groupwisesalereport')),
    exact: true,
  },
  {
    path: '/reports/report/group-wise-gross-profit-report',
    Component: lazy(() => import('pages/reports/reports/groupwisegrossprofitreport')),
    exact: true,
  },
  {
    path: '/reports/report/stock-report',
    Component: lazy(() => import('pages/reports/reports/stockreport')),
    exact: true,
  },
  {
    path: '/reports/report/stock-in-out-report',
    Component: lazy(() => import('pages/reports/reports/stockinoutreport')),
    exact: true,
  },
  {
    path: '/reports/report/product-ledger',
    Component: lazy(() => import('pages/reports/reports/productledger')),
    exact: true,
  },
  {
    path: '/reports/report/bill-wise-gross-profit',
    Component: lazy(() => import('pages/reports/reports/billwisegrossprofit')),
    exact: true,
  },
  {
    path: '/reports/report/bill-tax-wise-gross-profit',
    Component: lazy(() => import('pages/reports/reports/billtaxwisegrossprofit')),
    exact: true,
  },
  {
    path: '/reports/report/cash-book-report',
    Component: lazy(() => import('pages/reports/reports/cashbookreport')),
    exact: true,
  },
  {
    path: '/reports/report/cashier-report',
    Component: lazy(() => import('pages/reports/reports/cashierreport')),
    exact: true,
  },
  {
    path: '/reports/report/account-ledger-report',
    Component: lazy(() => import('pages/reports/reports/accountledgerreport')),
    exact: true,
  },
  {
    path: '/reports/report/purchase-report',
    Component: lazy(() => import('pages/reports/reports/purchasereport')),
    exact: true,
  },
  {
    path: '/reports/report/purchase-tax-report',
    Component: lazy(() => import('pages/reports/reports/purchasereport')),
    exact: true,
  },
  {
    path: '/reports/report/purchase-report/purchase-return',
    Component: lazy(() => import('pages/reports/reports/purchasereport')),
    exact: true,
  },
  {
    path: '/reports/report/purchase-report/purchase-tax-return',
    Component: lazy(() => import('pages/reports/reports/purchasereport')),
    exact: true,
  },
  // {
  //   path: '/reports/report/purchase-report/business-unit-wise-purchase-report',
  //   Component: lazy(() => import('pages/reports/reports/purchasereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/purchase-report/supplier-wise-purchase-report',
  //   Component: lazy(() => import('pages/reports/reports/purchasereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/purchase-report/business-unit-wise-purchase-return-report',
  //   Component: lazy(() => import('pages/reports/reports/purchasereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/purchase-report/supplier-wise-purchase-return-report',
  //   Component: lazy(() => import('pages/reports/reports/purchasereport')),
  //   exact: true,
  // },
  {
    path: '/reports/report/sale-report',
    Component: lazy(() => import('pages/reports/reports/salereport')),
    exact: true,
  },
  {
    path: '/reports/report/sale-tax-report',
    Component: lazy(() => import('pages/reports/reports/salereport')),
    exact: true,
  },
  {
    path: '/reports/report/sale-report/sale-return',
    Component: lazy(() => import('pages/reports/reports/salereport')),
    exact: true,
  },
  {
    path: '/reports/report/sale-report/sale-tax-return',
    Component: lazy(() => import('pages/reports/reports/salereport')),
    exact: true,
  },
  // {
  //   path: '/reports/report/sale-report/business-unit-wise-sale-report',
  //   Component: lazy(() => import('pages/reports/reports/salereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/sale-report/group-wise-sale-report',
  //   Component: lazy(() => import('pages/reports/reports/salereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/sale-report/category-wise-sale-report',
  //   Component: lazy(() => import('pages/reports/reports/salereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/sale-report/subcategory-wise-sale-report',
  //   Component: lazy(() => import('pages/reports/reports/salereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/sale-report/brand-wise-sale-report',
  //   Component: lazy(() => import('pages/reports/reports/salereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/sale-report/model-wise-sale-report',
  //   Component: lazy(() => import('pages/reports/reports/salereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/sale-report/party-wise-sale-report',
  //   Component: lazy(() => import('pages/reports/reports/salereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/item-wise-report',
  //   Component: lazy(() => import('pages/reports/reports/itemwisereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/business-unit-wise-report',
  //   Component: lazy(() => import('pages/reports/reports/itemwisereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/group-unit-wise-report',
  //   Component: lazy(() => import('pages/reports/reports/itemwisereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/category-unit-wise-report',
  //   Component: lazy(() => import('pages/reports/reports/itemwisereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/subcategory-unit-wise-report',
  //   Component: lazy(() => import('pages/reports/reports/itemwisereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/brand-unit-wise-report',
  //   Component: lazy(() => import('pages/reports/reports/itemwisereport')),
  //   exact: true,
  // },
  // {
  //   path: '/reports/report/model-unit-wise-report',
  //   Component: lazy(() => import('pages/reports/reports/itemwisereport')),
  //   exact: true,
  // },
  // ******************** AUTH PAGES ********************
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/setup-password',
    Component: lazy(() => import('pages/auth/setup-password')),
    exact: true,
  },
  // {
  //   path: '/auth/register',
  //   Component: lazy(() => import('pages/auth/register')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/lockscreen',
  //   Component: lazy(() => import('pages/auth/lockscreen')),
  //   exact: true,
  // },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/auth/error',
    Component: lazy(() => import('pages/auth/error')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard/alpha" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={<FallbackSpinner />}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
