import { all } from 'redux-saga/effects'

import pi from './purchase-invoices/purchase-invoice/sagas'
import pit from './purchase-invoices/purchase-tax-invoice/sagas'
import pri from './purchase-invoices/purchase-return-invoice/sagas'
import prit from './purchase-invoices/purchase-tax-return-invoice/sagas'
import si from './sale-invoices/sale-invoice/sagas'
import sit from './sale-invoices/sale-tax-invoice/sagas'
import sri from './sale-invoices/sale-return-invoice/sagas'
import srit from './sale-invoices/sale-tax-return-invoice/sagas'

export default function* rootSaga() {
  yield all([pi(), pit(), pri(), prit(), si(), sit(), sri(), srit()])
}
