const actions = {
  SET_STATE: 'gr/SET_STATE',

  ADD: 'gr/ADD',
  UPDATE: 'gr/UPDATE',
  DELETE: 'gr/DELETE',

  GET_LIST: 'gr/GET_LIST',
  RESET_LIST: 'gr/RESET_LIST',
  GET_NEXT_NO: 'gr/NEXT_NO',
  GET_EDITABLE: 'gr/GET_EDITABLE',
  RESET_EDITABLE: 'gr/RESET_EDITABLE',

  GET_DETAILS: 'gr/GET_DETAILS',
  RESET_DETAILS: 'gr/RESET_DETAILS',

  RESET_SAVING_STATUS: 'gr/RESET_SAVING_STATUS',

  GET_PREV_NEXT_IDS: 'gr/GET_PREV_NEXT_IDS',
}

export default actions
