export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Home',
    },
    {
      title: 'Dashboards',
      key: 'dashboards',
      icon: 'fe fe-home',
      // roles: ['admin'], // set user roles with access to this route
      count: 1,
      children: [
        {
          title: 'Dashboard Alpha',
          key: 'dashboardAlpha',
          url: '/dashboard/alpha',
        },
      ],
    },

    {
      category: true,
      title: 'Registration',
    },
    {
      title: 'Business ',
      key: 'bizReg',
      icon: 'fe fe-cpu',
      children: [
        {
          title: 'Business Information',
          key: 'bizInfo',
          url: '/registration/business/company',
        },
        {
          title: 'Sub Business Unit',
          key: 'bizRegSbu',
          url: '/registration/business/sbu-list',
          create_url: '/registration/business/sbu',
        },
      ],
    },
    {
      title: 'Account ',
      key: 'accReg',
      icon: 'fe fe-briefcase',
      children: [
        // {
        //   title: 'Account Access Group',
        //   key: 'accRegAccountAccessGroup',
        //   url: '/registration/account/account-access-group',
        //   create_url: '/registration/account/account-access-group',
        // },
        {
          title: 'Account Groups',
          key: 'accRegAccountAccessGroupList',
          url: '/registration/account/account-access-group-list',
          create_url: '/registration/account/account-access-group',
        },
        // {
        //   title: 'Account',
        //   key: 'accRegAccount',
        //   url: '/registration/account/account',
        // },
        {
          title: 'Accounts',
          key: 'accRegAccountList',
          url: '/registration/account/account-list',
          create_url: '/registration/account/account',
        },
        // {
        //   title: 'Bank Account',
        //   key: 'accRegBankAccount',
        //   url: '/registration/account/bank-account',
        // },
        {
          title: 'Bank Accounts',
          key: 'accRegBankAccountList',
          url: '/registration/account/bank-account-list',
          create_url: '/registration/account/bank-account',
        },
        // {
        //   title: 'Fixed Asset',
        //   key: 'accRegFixedAsset',
        //   url: '/registration/account/fixed-asset',
        // },
        {
          title: 'Fixed Assets',
          key: 'accRegFixedAsset',
          icon: '',
          children: [
            {
              title: 'Fixed Assets Purchase',
              key: 'accRegFixedAssetList',
              url: '/registration/account/fixed-asset-list',
              create_url: '/registration/account/fixed-asset',
            },
            {
              title: 'Fixed Assets Sale',
              key: 'accRegFixedAssetSaleList',
              url: '/registration/account/fixed-asset-Sale-list',
              create_url: '/registration/account/fixed-asset-Sale',
            },
          ],
        },
        // {
        //   title: 'Capital Account',
        //   key: 'accRegCapitalAccount',
        //   url: '/registration/account/capital',
        // },
        {
          title: 'Capital Accounts',
          key: 'accRegCapitalAccountList',
          url: '/registration/account/capital-list',
          create_url: '/registration/account/capital',
        },
        {
          title: 'Accounts Openings',
          key: 'accOpenings',
          url: '/registration/account/opening',
        },
      ],
    },
    {
      title: 'Chart of Accounts (COA)',
      key: 'coa',
      icon: 'fe fe-book-open',
      children: [
        {
          title: 'Chart of Accounts',
          key: 'coaChartOfAccounts',
          url: '/registration/coa/chart-of-accounts',
        },
        // {
        //   title: 'COA Group',
        //   key: 'coaCoaGroup',
        //   url: '/registration/coa/group',
        // },
        {
          title: 'COA Groups',
          key: 'coaCoaGroupList',
          url: '/registration/coa/group-list',
          create_url: '/registration/coa/group',
        },
        // {
        //   title: 'COA Subgroup',
        //   key: 'coaCoaSubgroup',
        //   url: '/registration/coa/subgroup',
        // },
        {
          title: 'COA Subgroups',
          key: 'coaCoaSubgroupList',
          url: '/registration/coa/subgroup-list',
          create_url: '/registration/coa/subgroup',
        },
      ],
    },
    {
      title: 'Party ',
      key: 'partyReg',
      icon: 'fe fe-hard-drive',
      children: [
        // {
        //   title: 'Region',
        //   key: 'partyRegRegion',
        //   url: '/registration/party/region',
        // },
        {
          title: 'Regions',
          key: 'partyRegRegionList',
          url: '/registration/party/region-list',
          create_url: '/registration/party/region',
        },
        // {
        //   title: 'Area',
        //   key: 'partyRegArea',
        //   url: '/registration/party/area',
        // },
        {
          title: 'Areas',
          key: 'partyRegAreaList',
          url: '/registration/party/area-list',
          create_url: '/registration/party/area',
        },
        // {
        //   title: 'Sector',
        //   key: 'partyRegSector',
        //   url: '/registration/party/sector',
        // },
        {
          title: 'Sectors',
          key: 'partyRegSectorList',
          url: '/registration/party/sector-list',
          create_url: '/registration/party/sector',
        },
        // {
        //   title: 'Client',
        //   key: 'partyRegClient',
        //   url: '/registration/party/client',
        // },
        {
          title: 'Customer',
          key: 'partyRegClientList',
          url: '/registration/party/client-list',
          create_url: '/registration/party/client',
        },
        // {
        //   title: 'Supplier',
        //   key: 'partyRegSupplier',
        //   url: '/registration/party/supplier',
        // },
        {
          title: 'Suppliers',
          key: 'partyRegSupplierList',
          url: '/registration/party/supplier-list',
          create_url: '/registration/party/supplier',
        },
      ],
    },
    {
      title: 'Product and Services',
      key: 'productReg',
      icon: 'fe fe-package',
      children: [
        // {
        //   title: 'Main Unit',
        //   key: 'productRegMainUnit',
        //   url: '/registration/product/main-unit',
        // },
        {
          title: 'Main Units',
          key: 'productRegMainUnitList',
          url: '/registration/product/main-unit-list',
          create_url: '/registration/product/main-unit',
        },
        // {
        //   title: 'Unit',
        //   key: 'productRegUnit',
        //   url: '/registration/product/unit',
        // },
        {
          title: 'Units',
          key: 'productRegUnitList',
          url: '/registration/product/unit-list',
          create_url: '/registration/product/unit',
        },
        // {
        //   title: 'Product Group',
        //   key: 'productRegProductGroup',
        //   url: '/registration/product/group',
        // },
        {
          title: 'Product Groups',
          key: 'productRegProductGroupList',
          url: '/registration/product/group-list',
          create_url: '/registration/product/group',
        },
        // {
        //   title: 'Product Category',
        //   key: 'productRegProductCategory',
        //   url: '/registration/product/category',
        // },
        {
          title: 'Product Categories',
          key: 'productRegProductCategoryList',
          url: '/registration/product/category-list',
          create_url: '/registration/product/category',
        },
        {
          title: 'Product SubCategories',
          key: 'productRegProductSubCategoryList',
          url: '/registration/product/sub-category-list',
          create_url: '/registration/product/sub-category',
        },
        // {
        //   title: 'Product Brand',
        //   key: 'productRegProductBrand',
        //   url: '/registration/product/brand',
        // },
        {
          title: 'Product Brands',
          key: 'productRegProductBrandList',
          url: '/registration/product/brand-list',
          create_url: '/registration/product/brand',
        },
        // {
        //   title: 'Product Model',
        //   key: 'productRegProductModel',
        //   url: '/registration/product/model',
        // },
        {
          title: 'Product Models',
          key: 'productRegProductModelList',
          url: '/registration/product/model-list',
          create_url: '/registration/product/model',
        },
        // {
        //   title: 'Product',
        //   key: 'productRegProduct',
        //   url: '/registration/product/product',
        // },
        {
          title: 'Products',
          key: 'productRegProductList',
          url: '/registration/product/product-list',
          create_url: '/registration/product/product',
        },
        {
          title: 'Services',
          key: 'generalRegServiceList',
          url: '/registration/general/service-list',
          create_url: '/registration/general/service',
        },
        // {
        //   title: 'Package Group',
        //   key: 'productRegPackageGroup',
        //   url: '/registration/product/package-group',
        // },
        {
          title: 'Package Groups',
          key: 'productRegPackageGroupList',
          url: '/registration/product/package-group-list',
          create_url: '/registration/product/package-group',
        },
        // {
        //   title: 'Package',
        //   key: 'productRegPackage',
        //   url: '/registration/product/package',
        // },
        {
          title: 'Packages',
          key: 'productRegPackageList',
          url: '/registration/product/package-list',
          create_url: '/registration/product/package',
        },
        {
          title: 'Products Openings',
          key: 'prodOpenings',
          url: '/registration/product/opening',
        },
      ],
    },
    {
      title: 'General ',
      key: 'generalReg',
      icon: 'fe fe-box',
      children: [
        // {
        //   title: 'Warehouse',
        //   key: 'generalRegWarehouse',
        //   url: '/registration/general/warehouse',
        // },
        {
          title: 'Warehouses',
          key: 'generalRegWarehouseList',
          url: '/registration/general/warehouse-list',
          create_url: '/registration/general/warehouse',
        },
        // {
        //   title: 'Service',
        //   key: 'generalRegService',
        //   url: '/registration/general/service',
        // },
        // {
        //   title: 'Services',
        //   key: 'generalRegServiceList',
        //   url: '/registration/general/service-list',
        //   create_url: '/registration/general/service',
        // },
      ],
    },
    {
      title: 'Human Resources',
      key: 'humanRes',
      icon: 'fe fe-user',
      children: [
        // {
        //   title: 'User',
        //   key: 'humanResUser',
        //   url: '/registration/human-resources/user',
        // },
        {
          title: 'Users',
          key: 'humanResUserList',
          url: '/registration/human-resources/user-list',
          create_url: '/registration/human-resources/user',
        },
        // {
        //   title: 'Salary User',
        //   key: 'humanResSalaryUser',
        //   url: '/registration/human-resources/salary-user',
        // },
        {
          title: 'Salary Users',
          key: 'humanResSalaryUserList',
          url: '/registration/human-resources/salary-user-list',
          create_url: '/registration/human-resources/salary-user',
        },
        // {
        //   title: 'Department',
        //   key: 'humanResDepartmentList',
        //   url: '/registration/human-resources/Department-list',
        //   create_url: '/registration/human-resources/Department',
        // },
      ],
    },
    {
      title: 'Commissions',
      key: 'commissionResource',
      icon: 'fe fe-user',
      children: [
        {
          title: 'Commissions',
          key: 'commissionResourceList',
          url: '/registration/commission-resources/commission-list',
          // create_url: '/registration/commission-resources/commission',
        },
        {
          title: 'Users',
          key: 'commissionUserList',
          url: '/registration/commission-resources/user-list',
        },
      ],
    },
    {
      title: 'Stock Management',
      key: 'stockManagementResource',
      icon: 'fe fe-box',
      children: [
        {
          title: 'Stock Excess',
          key: 'stockExcessResource',
          url: '/registration/stock-resource/stock-excess',
        },
        {
          title: 'Stock Write Off',
          key: 'stockWriteOffResource',
          url: '/registration/stock-resource/stock-write-off',
        },
      ],
    },

    {
      category: true,
      title: 'Preferences',
    },
    {
      title: 'System',
      key: 'sys',
      icon: 'fe fe-settings',
      children: [
        {
          title: 'System Users',
          key: 'sysUserList',
          url: '/system/user-list',
        },
        {
          title: 'SBU Users',
          key: 'sysSbuUsers',
          url: '/system/sbu-users',
        },
        {
          title: 'Shareholders',
          key: 'sysShareholderList',
          url: '/system/shareholder-list',
        },
        {
          title: 'System Settings',
          key: 'sysSystemsetting',
          url: '/system/system-setting',
        },
      ],
    },

    {
      category: true,
      title: 'Receipts & Payments',
    },
    {
      title: 'Cash Vouchers',
      key: 'cashVouchers',
      icon: 'fe fe-dollar-sign',
      children: [
        // {
        //   title: 'Cash Receipt Voucher',
        //   key: 'cashReceiptVoucher',
        //   url: '/vouchers/cash-receipt-voucher',
        // },
        {
          title: 'Cash Receipt Vouchers',
          key: 'cashReceiptVoucherList',
          url: '/vouchers/cash-receipt-voucher-list',
          create_url: '/vouchers/cash-receipt-voucher',
        },
        // {
        //   title: 'Cash Payment Voucher',
        //   key: 'cashPaymentVoucher',
        //   url: '/vouchers/cash-payment-voucher',
        // },
        {
          title: 'Cash Payment Vouchers',
          key: 'cashPaymentVoucherList',
          url: '/vouchers/cash-payment-voucher-list',
          create_url: '/vouchers/cash-payment-voucher',
        },
      ],
    },
    {
      title: 'Bank Vouchers',
      key: 'bankVouchers',
      icon: 'fe fe-credit-card',
      children: [
        // {
        //   title: 'Bank Receipt Voucher',
        //   key: 'bankReceiptVoucher',
        //   url: '/vouchers/bank-receipt-voucher',
        // },
        {
          title: 'Bank Receipt Vouchers',
          key: 'bankReceiptVoucherList',
          url: '/vouchers/bank-receipt-voucher-list',
          create_url: '/vouchers/bank-receipt-voucher',
        },
        // {
        //   title: 'Bank Payment Voucher',
        //   key: 'bankPaymentVoucher',
        //   url: '/vouchers/bank-payment-voucher',
        // },
        {
          title: 'Bank Payment Vouchers',
          key: 'bankPaymentVoucherList',
          url: '/vouchers/bank-payment-voucher-list',
          create_url: '/vouchers/bank-payment-voucher',
        },
      ],
    },
    {
      title: 'Journal Vouchers',
      key: 'vouchers',
      icon: 'fe fe-shuffle',
      children: [
        // {
        //   title: 'Journal Voucher (JV)',
        //   key: 'jv',
        //   url: '/vouchers/jv',
        // },
        {
          title: 'Journal Vouchers (JV)',
          key: 'jvList',
          url: '/vouchers/jv-list',
          create_url: '/vouchers/jv',
        },
      ],
    },
    {
      title: 'Salary Vouchers',
      key: 'salaryVouchers',
      icon: 'fe fe-user',
      children: [
        // {
        //   title: 'Salary Slip',
        //   key: 'salarySlip',
        //   url: '/vouchers/salary-slip',
        // },
        {
          title: 'Salary Slips',
          key: 'salarySlipList',
          url: '/vouchers/salary-slip-list',
          create_url: '/vouchers/salary-slip',
        },
      ],
    },

    // {
    //   category: true,
    //   title: 'Quotes',
    // },
    // {
    //   title: 'Purchases',
    //   key: 'purchasesQuotes',
    //   icon: 'fe fe-shopping-cart',
    //   children: [
    //     {
    //       title: 'Purchase Quotes',
    //       key: 'purchaseQuoteList',
    //       url: '/quotes/purchase-quote-list',
    //       create_url: '/quotes/purchase-quote',
    //     },
    //     {
    //       title: 'Purchase Tax Quotes',
    //       key: 'purchaseTaxQuoteList',
    //       url: '/quotes/purchase-tax-quote-list',
    //       create_url: '/quotes/purchase-tax-quote',
    //     },
    //   ],
    // },
    // {
    //   title: 'Sales',
    //   key: 'salesQuotes',
    //   icon: 'fe fe-shopping-cart',
    //   children: [
    //     {
    //       title: 'Sale Quotes',
    //       key: 'saleQuoteList',
    //       url: '/quotes/sale-quote-list',
    //       create_url: '/quotes/sale-quote',
    //     },
    //     {
    //       title: 'Sale Tax Quotes',
    //       key: 'saleTaxQuoteList',
    //       url: '/quotes/sale-tax-quote-list',
    //       create_url: '/quotes/sale-tax-quote',
    //     },
    //   ],
    // },
    // {
    //   category: true,
    //   title: 'Orders',
    // },
    // {
    //   title: 'Purchase',
    //   key: 'purchaseOrder',
    //   icon: 'fe fe-shopping-cart',
    //   children: [
    //     {
    //       title: 'Purchase Order',
    //       key: 'currentPurchaseOrder',
    //       url: '/orders/purchase-order-list',
    //       create_url: '/orders/purchase-order',
    //     },
    //   ],
    // },
    // {
    //   title: 'Sale',
    //   key: 'saleOrder',
    //   icon: 'fe fe-shopping-cart',
    //   children: [
    //     {
    //       title: 'Sale Order',
    //       key: 'currentSaleOrder',
    //       url: '/orders/sale-order-list',
    //       create_url: '/orders/sale-order',
    //     },
    //   ],
    // },
    {
      category: true,
      title: 'Invoices',
    },
    {
      title: 'Purchases',
      key: 'purchases',
      icon: 'fe fe-shopping-cart',
      children: [
        // {
        //   title: 'Purchase Invoice',
        //   key: 'purchaseInvoice',
        //   url: '/invoices/purchase-invoice',
        // },
        {
          title: 'Purchase Invoices',
          key: 'purchaseInvoiceList',
          url: '/invoices/purchase-invoice-list',
          create_url: '/invoices/purchase-invoice',
        },
        {
          title: 'Purchase Tax Invoices',
          key: 'purchaseTaxInvoiceList',
          url: '/invoices/purchase-tax-invoice-list',
          create_url: '/invoices/purchase-tax-invoice',
        },
        // {
        //   title: 'Purchase Return Invoice',
        //   key: 'purchaseReturnInvoice',
        //   url: '/invoices/purchase-return-invoice',
        // },
        {
          title: 'Purchase Return Invoices',
          key: 'purchaseReturnInvoiceList',
          url: '/invoices/purchase-return-invoice-list',
          create_url: '/invoices/purchase-return-invoice',
        },
        {
          title: 'Purchase Tax Return Invoices',
          key: 'purchaseReturnInvoiceListTax',
          url: '/invoices/purchase-tax-return-invoice-list',
          create_url: '/invoices/purchase-tax-return-invoice',
        },
        {
          title: 'Purchase Order',
          key: 'currentPurchaseOrder',
          url: '/orders/purchase-order-list',
          create_url: '/orders/purchase-order',
        },
        {
          title: 'Purchase Quotes',
          key: 'purchaseQuoteList',
          url: '/quotes/purchase-quote-list',
          create_url: '/quotes/purchase-quote',
        },
        {
          title: 'Purchase Tax Quotes',
          key: 'purchaseTaxQuoteList',
          url: '/quotes/purchase-tax-quote-list',
          create_url: '/quotes/purchase-tax-quote',
        },
      ],
    },
    {
      title: 'Sales',
      key: 'sales',
      icon: 'fe fe-shopping-cart',
      children: [
        // {
        //   title: 'Sale Invoice',
        //   key: 'saleInvoice',
        //   url: '/invoices/sale-invoice',
        // },
        {
          title: 'Sale Invoices',
          key: 'saleInvoiceList',
          url: '/invoices/sale-invoice-list',
          create_url: '/invoices/sale-invoice',
        },
        {
          title: 'Sale Invoices Tax',
          key: 'saleTaxInvoiceList',
          url: '/invoices/sale-tax-invoice-list',
          create_url: '/invoices/sale-tax-invoice',
        },
        // {
        //   title: 'Sale Return Invoice',
        //   key: 'saleReturnInvoice',
        //   url: '/invoices/sale-return-invoice',
        // },
        {
          title: 'Sale Return Invoices',
          key: 'saleReturnInvoiceList',
          url: '/invoices/sale-return-invoice-list',
          create_url: '/invoices/sale-return-invoice',
        },
        {
          title: 'Sale Return Invoices Tax',
          key: 'saleTaxReturnInvoiceList',
          url: '/invoices/sale-tax-return-invoice-list',
          create_url: '/invoices/sale-tax-return-invoice',
        },
        {
          title: 'Sale Order',
          key: 'currentSaleOrder',
          url: '/orders/sale-order-list',
          create_url: '/orders/sale-order',
        },
        {
          title: 'Sale Quotes',
          key: 'saleQuoteList',
          url: '/quotes/sale-quote-list',
          create_url: '/quotes/sale-quote',
        },
        {
          title: 'Sale Tax Quotes',
          key: 'saleTaxQuoteList',
          url: '/quotes/sale-tax-quote-list',
          create_url: '/quotes/sale-tax-quote',
        },
      ],
    },
    {
      category: true,
      title: 'Notes & Receipts',
    },
    {
      title: 'Notes',
      key: 'notes',
      icon: 'fe fe-credit-card',
      children: [
        {
          title: 'Delivery Notes',
          key: 'deliveryNotes',
          url: '/notes/delivery-notes-list',
          create_url: '/notes/delivery-notes',
        },
      ],
    },
    {
      title: 'Receipts',
      key: 'receipts',
      icon: 'fe fe-shuffle',
      children: [
        {
          title: 'Goods Receipt',
          key: 'goodsReceipt',
          url: '/notes/goods-receipt-list',
          create_url: '/notes/goods-receipt',
        },
      ],
    },
    {
      category: true,
      title: 'Reports',
    },
    {
      title: 'Finance',
      key: 'finance',
      icon: 'fe fe-hard-drive',
      children: [
        {
          title: 'Accounts List Report',
          key: 'rptAccountsList',
          url: '/reports/financial/accounts-list',
        },
        {
          title: 'Day End',
          key: 'rptDayEnd',
          url: '/reports/financial/day-end',
        },
        {
          title: 'Day End Report',
          key: 'dayEndReport',
          url: '/reports/financial/day-end-report',
        },
      ],
    },
    {
      title: 'Stock',
      key: 'stock',
      icon: 'fe fe-hard-drive',
      children: [
        {
          title: 'Products List Stock',
          key: 'rptProductsList',
          url: '/reports/stock/products-list',
        },
        {
          title: 'Stock Flow Statement',
          key: 'rptStockFlow',
          url: '/reports/stock/stock-flow',
        },
        // {
        //   title: 'On Hand Stock Report',
        //   key: 'rptOnHandStockReport',
        //   url: '/reports/stock/on-hand-stock-report',
        // },
      ],
    },
    {
      title: 'General Reports',
      key: 'reports',
      icon: 'fe fe-hard-drive',
      children: [
        {
          title: 'Audit Report',
          key: 'rptAuditReport',
          url: '/reports/report/audit-report',
        },
        {
          title: 'Transaction Report',
          key: 'rptTransactionReport',
          url: '/reports/report/transaction-report',
        },
        {
          title: 'Daily Voucher Report',
          key: 'rptDailyVoucherReport',
          url: '/reports/report/daily-voucher-report',
        },
        {
          title: 'Product Wise Gross Profit Report',
          key: 'rptProductWiseGrossProfitReport',
          url: '/reports/report/product-wise-gross-profit-report',
        },
        {
          title: 'Group Wise Sale Report',
          key: 'rptGroupWiseSaleReport',
          url: '/reports/report/group-wise-sale-report',
        },
        {
          title: 'Group Wise Gross Profit Report',
          key: 'rptGroupWiseGrossProfitReport',
          url: '/reports/report/group-wise-gross-profit-report',
        },
        {
          title: 'Stock Report',
          key: 'rptStockReport',
          url: '/reports/report/stock-report',
        },
        {
          title: 'Stock In Out Report',
          key: 'rptStockInOutReport',
          url: '/reports/report/stock-in-out-report',
        },
        {
          title: 'Product Ledger',
          key: 'rptProductLedger',
          url: '/reports/report/product-ledger',
        },
        {
          title: 'Bill/Sale Wise Gross Profit',
          key: 'rptBillWiseGrossProfit',
          url: '/reports/report/bill-wise-gross-profit',
        },
        {
          title: 'Bill/Sale Tax Wise Gross Profit',
          key: 'rptBillTaxWiseGrossProfit',
          url: '/reports/report/bill-tax-wise-gross-profit',
        },
        {
          title: 'Cash Book',
          key: 'rptCashBookReport',
          url: '/reports/report/cash-book-report',
        },
        {
          title: 'Cashier Report',
          key: 'rptCashierReport',
          url: '/reports/report/cashier-report',
        },
        {
          title: 'Account Ledger',
          key: 'rptAccountLedgerReport',
          url: '/reports/report/account-ledger-report',
        },
        {
          title: 'Purchase Report',
          key: 'rptPurchaseReport',
          url: '/reports/report/purchase-report',
        },
        {
          title: 'Purchase Tax Report',
          key: 'rptPurchaseTaxReport',
          url: '/reports/report/purchase-tax-report',
        },
        // {
        //   title: 'Business Unit Wise Purchase Report',
        //   key: 'rptBusinessUnitWisePurchaseReport',
        //   url: '/reports/report/purchase-report/business-unit-wise-purchase-report',
        // },
        // {
        //   title: 'Supplier Wise Purchase Report',
        //   key: 'rptSupplierWisePurchaseReport',
        //   url: '/reports/report/purchase-report/supplier-wise-purchase-report',
        // },
        {
          title: 'Purchase Return Report',
          key: 'rptPurchaseReturnReport',
          url: '/reports/report/purchase-report/purchase-return',
        },
        {
          title: 'Purchase Tax Return Report',
          key: 'rptPurchaseTaxReturnReport',
          url: '/reports/report/purchase-report/purchase-tax-return',
        },
        // {
        //   title: 'Business Unit Wise Purchase Return Report',
        //   key: 'rptBusinessUnitWisePurchaseReturnReport',
        //   url: '/reports/report/purchase-report/business-unit-wise-purchase-return-report',
        // },
        // {
        //   title: 'Supplier Wise Purchase Return Report',
        //   key: 'rptSupplierWisePurchaseReturnReport',
        //   url: '/reports/report/purchase-report/supplier-wise-purchase-return-report',
        // },
        {
          title: 'Sale Report',
          key: 'rptSaleReport',
          url: '/reports/report/sale-report',
        },
        {
          title: 'Sale Tax Report',
          key: 'rptSaleTaxReport',
          url: '/reports/report/sale-tax-report',
        },
        {
          title: 'Sale Return Report',
          key: 'rptSaleReturnReport',
          url: '/reports/report/sale-report/sale-return',
        },
        {
          title: 'Sale Tax Return Report',
          key: 'rptSaleTaxReturnReport',
          url: '/reports/report/sale-report/sale-tax-return',
        },
        // {
        //   title: 'Business Unit Wise Sale Report',
        //   key: 'rptBusinessUnitWiseSaleReport',
        //   url: '/reports/report/sale-report/business-unit-wise-sale-report',
        // },
        // {
        //   title: 'Group Wise Sale Report',
        //   key: 'rptGroupWiseSaleReport',
        //   url: '/reports/report/sale-report/group-wise-sale-report',
        // },
        // {
        //   title: 'Category Wise Sale Report',
        //   key: 'rptCategoryWiseSaleReport',
        //   url: '/reports/report/sale-report/category-wise-sale-report',
        // },
        // {
        //   title: 'Subcategory Wise Sale Report',
        //   key: 'rptSubcategoryWiseSaleReport',
        //   url: '/reports/report/sale-report/subcategory-wise-sale-report',
        // },
        // {
        //   title: 'Brand Wise Sale Report',
        //   key: 'rptBrandWiseSaleReport',
        //   url: '/reports/report/sale-report/brand-wise-sale-report',
        // },
        // {
        //   title: 'Model Wise Sale Report',
        //   key: 'rptModelWiseSaleReport',
        //   url: '/reports/report/sale-report/model-wise-sale-report',
        // },
        // {
        //   title: 'Party Wise Sale Report',
        //   key: 'rptPartyWiseSaleReport',
        //   url: '/reports/report/sale-report/party-wise-sale-report',
        // },
        // {
        //   title: 'Item Wise Profit/Loss Report',
        //   key: 'rptItemWiseProfitLossReport',
        //   url: '/reports/report/item-wise-report',
        // },
        // {
        //   title: 'Business Unit Wise Profit/Loss Report',
        //   key: 'rptBusinessUnitWiseProfitLossReport',
        //   url: '/reports/report/business-unit-wise-report',
        // },
        // {
        //   title: 'Group Wise Profit/Loss Report',
        //   key: 'rptGroupWiseProfitLossReport',
        //   url: '/reports/report/group-unit-wise-report',
        // },
        // {
        //   title: 'Category Wise Profit/Loss Report',
        //   key: 'rptCategoryWiseProfitLossReport',
        //   url: '/reports/report/category-unit-wise-report',
        // },
        // {
        //   title: 'Subcategory Wise Profit/Loss Report',
        //   key: 'rptSubcategoryWiseProfitLossReport',
        //   url: '/reports/report/subcategory-unit-wise-report',
        // },
        // {
        //   title: 'Brand Wise Profit/Loss Report',
        //   key: 'rptBrandWiseProfitLossReport',
        //   url: '/reports/report/brand-unit-wise-report',
        // },
        // {
        //   title: 'Model Wise Profit/Loss Report',
        //   key: 'rptModelWiseProfitLossReport',
        //   url: '/reports/report/model-unit-wise-report',
        // },
      ],
    },
  ]
}
