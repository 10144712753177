import { all } from 'redux-saga/effects'

import pq from './purchase-quotes/purchase-quote/sagas'
import pqt from './purchase-quotes/purchase-tax-quote/sagas'
import sq from './sale-quotes/sale-quote/sagas'
import sqt from './sale-quotes/sale-tax-quote/sagas'

export default function* rootSaga() {
  yield all([pq(), pqt(), sq(), sqt()])
}
