const actions = {
  SET_STATE: 'sq/SET_STATE',

  ADD: 'sq/ADD',
  UPDATE: 'sq/UPDATE',
  DELETE: 'sq/DELETE',

  GET_LIST: 'sq/GET_LIST',
  RESET_LIST: 'sq/RESET_LIST',
  GET_NEXT_NO: 'sq/NEXT_NO',
  GET_CGS: 'sq/CGS',
  GET_EDITABLE: 'sq/GET_EDITABLE',
  RESET_EDITABLE: 'sq/RESET_EDITABLE',

  GET_DETAILS: 'sq/GET_DETAILS',
  RESET_DETAILS: 'sq/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'si/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'si/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'si/GET_LOOKUPS',
  // RESET_LOOKUPS: 'si/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'sq/RESET_SAVING_STATUS',

  GET_PREV_NEXT_IDS: 'sq/GET_PREV_NEXT_IDS',
}

export default actions
