const actions = {
  SET_STATE: 'stockWriteOff/SET_STATE',

  ADD: 'stockWriteOff/ADD',

  GET_LIST: 'stockWriteOff/GET_LIST',
  RESET_LIST: 'stockWriteOff/RESET_LIST',

  RESET_SAVING_STATUS: 'stockWriteOff/RESET_SAVING_STATUS',
}

export default actions
