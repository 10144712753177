import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as httpService from 'services/http'
import { notification } from 'antd'
import actions from './actions'

const endpoint = '/day_end'
const submitEndpoint = '/day_end/submit'

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint, queryParams)
  if (response) {
    if (response.data.dayEndErrors && response.data.dayEndErrors.length) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          cashErrorData: response.data.dayEndErrors[0].cashError,
          bankErrorData: response.data.dayEndErrors[0].bankError,
          productErrorData: response.data.dayEndErrors[0].productError,
          isLoadingList: false,
          isErrorList: true,
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          openingTrialBalance: response.data.openingTrialBalance
            ? response.data.openingTrialBalance
            : [],
          closingTrialBalance: response.data.closingTrialBalance
            ? response.data.closingTrialBalance
            : [],
          trialBalanceData: response.data.trialBalance,
          incomeStatementData: response.data.incomeStatement,
          balanceSheetData: response.data.balanceSheet,
          totalDebit: response.data.totalDebit,
          totalCredit: response.data.totalCredit,
          isLoadingList: false,
          isErrorList: false,
        },
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* POST_DAY_END({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.addItem, submitEndpoint, queryParams)
  if (response) {
    notification.success({
      message: response.message,
      description: response.description,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        trialBalanceData: response.data.trialBalance,
        incomeStatementData: response.data.incomeStatement,
        balanceSheetData: response.data.balanceSheet,
        totalDebit: response.data.totalDebit,
        totalCredit: response.data.totalCredit,
        isLoadingList: false,
        isErrorList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      openingTrialBalance: [],
      closingTrialBalance: [],
      trialBalanceData: [],
      incomeStatementData: {},
      balanceSheetData: {},
      cashErrorData: [],
      bankErrorData: [],
      productErrorData: [],
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST, GET_LIST),
    takeLatest(actions.POST_DAY_END, POST_DAY_END),
    takeLatest(actions.RESET_LIST, RESET_LIST),
  ])
}
