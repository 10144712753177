import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as httpService from 'services/http'
import actions from './actions'

const endpoint = '/stocks/stock-write-off'

export function* ADD({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.addItem, endpoint, item)
  if (response) {
    notification.success({
      message: 'Stock Write Off Operation Successful',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.products,
        totalRecords: response.data.total_records,
        isLoadingList: false,
        isOpeningPosted: response.data.isOpeningPosted,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: [],
      totalRecords: 0,
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeLatest(actions.GET_LIST, GET_LIST),
    takeLatest(actions.RESET_LIST, RESET_LIST),
    takeLatest(actions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
