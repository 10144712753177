import actions from './actions'

const initialState = {
  transactionReport: [],
  isLoadingTransactionReport: false,
  dailyVoucherReport: [],
  isLoadingDailyVoucherReport: false,
  productGrossProfitReport: [],
  isLoadingProductGrossProfitReport: false,
  groupWiseSaleReport: [],
  isLoadingGroupWiseSaleReport: false,
  auditReport: [],
  isLoadingAuditReport: false,
  stockReport: [],
  isLoadingStockReport: false,
  stockInOutReport: [],
  isLoadingStockInOutReport: false,
  productLedger: [],
  isLoadingProductLedger: false,
  billWiseGrossProfitReport: [],
  isLoadingBillWiseGrossProfitReport: false,
  billTaxWiseGrossProfitReport: [],
  isLoadingBillTaxWiseGrossProfitReport: false,
  cashBookReport: [],
  isLoadingCashBookReport: false,
  cashierReport: [],
  isLoadingCashierReport: false,
  profitLossReport: [],
  isLoadingProfitLossReport: false,
  purchaseReport: [],
  isLoadingPurchaseReport: false,
  saleReport: [],
  isLoadingSaleReport: false,
  partyList: [],
  isLoadingPartyList: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
